<template>
    <div v-bind:class="classes" v-html="svg" :style="cssVars"></div>
</template>

<script>
  import { ref, computed } from "vue";

  export default {
    name: 'svg-icon',
    props: {
      icon: {
        type: String,
        required: true
      },
      classes: {
        type: String,
        required: false
      },
      width: {
        type: String,
        required: false
      },
      height: {
        type: String,
        required: false
      },
      fillColor: {
        type: String,
        required: false
      },
      strokeColor: {
        type: String,
        required: false
      },
      borderRadius: {
        type: String,
        required: false,
        default: '4px'
      }
    },
    setup(props) {
      const svg = require(`!html-loader!../images/${props.icon}.svg`);
      const cssVars = computed(() => {
        return {
          '--width': props.width,
          '--height': props.height,
          '--fill': props.fillColor,
          '--stroke': props.strokeColor,
          '--border-radius': props.borderRadius
        }
      })

      return {
        svg,
        cssVars
      }
    },
  }
</script>
<style lang="scss" scoped>
  div {
    border-radius: var(--border-radius);
  }
  div :deep(svg) {
    width: var(--width);
    height: var(--height);
    fill: var(--fill);
    stroke: var(--stroke);
  }
</style>
