import 'lodash';

let mi = 1609.344
let ft = 3.28084

const athleteTotals = (activities) => {
  return _(activities)
    .groupBy('athlete')
    .map((athlete) => ({
      athlete: (athlete[0].user) ? athlete[0].user.username : athlete[0].athlete,
      distance: _.sumBy(athlete, item => Number(item.distance)),
      elevation: _.sumBy(athlete, item => Number(item.elevation_gain)),
      time: _.sumBy(athlete, item => Number(item.moving_time))
    }))
    .value()
}

const sortBy = (arry, sortBy) => {
  return _(arry)
    .sortBy(sortBy)
    .reverse()
    .value();
}

const groupBy = (arry, groupBy) => {
  return _(arry)
    .groupBy(groupBy)
    .value();
}

const getUnit = (challenge_type) => {
  switch (challenge_type) {
    case 'distance':
      return 'miles';
    case 'elevation':
      return 'feet';
    case 'time':
      return 'minutes';
  }
}

const getTotal = (item, challenge_type) => {
  switch (challenge_type) {
    case 'distance':
      return parseFloat(item.distance / mi).toFixed(2);
    case 'elevation':
      return numberWithCommas(parseFloat(item.elevation * ft).toFixed(2));
    case 'time':
      return parseFloat(item.time / 60).toFixed(0);
  }
}

const getProgress = (goal, challenge_type, athlete_total) => {
  let totalMi = athlete_total.distance / mi
  let totalEl = athlete_total.elevation * ft
  let totalTi = athlete_total.time / 60

  switch (challenge_type) {
    case 'distance':
      return (totalMi / goal) * 100;
    case 'elevation':
      return (totalEl / goal) * 100;
    case 'time':
      return (totalTi / goal) * 100;
  }
}

const numberWithCommas = (n) => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? "h:" : "h:") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? "m:" : "m:") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
  return hDisplay + mDisplay + sDisplay;
}

export {
  athleteTotals,
  sortBy,
  groupBy,
  getUnit,
  getTotal,
  getProgress,
  numberWithCommas,
  secondsToHms
}