<template>
  <canvas ref="root" :id="options.elm"></canvas>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';

export default defineComponent({
  name: 'doughnut-chart',
  props: {
    chartdata: Object,
    options: Object,
    challenge: Object,
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    textColor: {
      type: String,
      default: '#fff',
    },
    strokeColor: {
      type: String,
      default: '#fff',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const root = ref(null);
    const radius = ref(null);
    const ctx = ref(null);
    const al = ref(0);
    const start = ref(4.72);
    const cw = ref(null);
    const ch = ref(null);
    const diff = ref(null);
    const sim = ref(null);
    const mi = ref(1609.344)
    const ft = ref(3.28084)
    const totalMi = ref(0)
    const totalEl = ref(0)
    const totalTi = ref(0)
    const currentProgress = ref(0)    
    const percText = ref(
      props.chartdata == 0 ? props.chartdata + '%' : props.chartdata
    );
    const compText = ref('COMPLETE');

    onMounted(() => {

      ctx.value = document.getElementById(props.options.elm).getContext('2d');
      ctx.value.canvas.width = props.width;
      ctx.value.canvas.height = props.height;
      cw.value = ctx.value.canvas.width;
      ch.value = ctx.value.canvas.height;
      for (let activity in props.chartdata) {
        totalMi.value += parseFloat(props.chartdata[activity].distance)
        totalTi.value += parseFloat(props.chartdata[activity].moving_time)
        if (props.chartdata[activity].elevation_gain != '') {
          totalEl.value += parseFloat(props.chartdata[activity].elevation_gain) 
        }
      }
      totalMi.value = totalMi.value/mi.value
      totalEl.value = totalEl.value*ft.value
      totalTi.value = totalTi.value/60
      
      switch(props.challenge.challenge_type) {
        case 'distance':
          currentProgress.value = (totalMi.value/props.challenge.target)*100
          break;
        case 'elevation':
          currentProgress.value = (totalEl.value/props.challenge.target)*100
          break;
        case 'time':
          currentProgress.value = (totalTi.value/props.challenge.target)*100
          break;
      }


      ctx.value = root.value.getContext('2d');
      ctx.value.canvas.width = props.width;
      ctx.value.canvas.height = props.height;
      radius.value = props.fullWidth ? cw.value * 0.375 : 75;
      cw.value = ctx.value.canvas.width;
      ch.value = ctx.value.canvas.height;
      ctx.value.fillStyle = props.textColor;
      ctx.value.strokeStyle = props.strokeColor;
      ctx.value.canvas.textAlign = 'center';
      ctx.value.canvas.font = '600 36px Inter';
      if (currentProgress.value === 0) {
        ctx.value.font = '600 36px Inter';
        ctx.value.fillText(
          percText.value,
          cw.value * 0.52,
          ch.value * 0.5 + 5,
          cw.value + 12
        );
        ctx.value.font = '600 15px Inter';
        ctx.value.fillText(
          compText.value,
          cw.value * 0.52,
          ch.value * 0.5 + 25,
          cw.value + 12
        );
        setInterval(setText, 100);
      } else {
        ctx.value.fillText(
          al.value + '%',
          cw.value * 0.52,
          ch.value * 0.5 + 5,
          cw.value + 12
        );
        sim.value = setInterval(progress, 10);
      }
    });

    const progress = () => {
      diff.value = ((al.value / 100) * Math.PI * 2 * 10).toFixed(2);
      ctx.value.clearRect(0, 0, cw.value, ch.value);
      ctx.value.lineWidth = 20;
      ctx.value.lineCap = 'round';
      ctx.value.fillStyle = props.textColor;
      ctx.value.strokeStyle = props.strokeColor;
      ctx.value.textAlign = 'center';
      ctx.value.font = '600 36px Inter';
      ctx.value.fillText(
        al.value + '%',
        cw.value * 0.52,
        ch.value * 0.5 + 5,
        cw.value + 12
      );
      ctx.value.font = '600 15px Inter';
      ctx.value.fillText(
        'COMPLETE',
        cw.value * 0.52,
        ch.value * 0.5 + 25,
        cw.value + 12
      );
      ctx.value.beginPath();
      ctx.value.arc(
        cw.value / 2,
        ch.value / 2,
        radius.value,
        start.value,
        diff.value / 10 + start.value,
        false
      );
      ctx.value.stroke();
      if (al.value >= currentProgress.value) {
        clearTimeout(sim.value);
      }
      al.value++;
    };

    const setText = () => {
      ctx.value.clearRect(0, 0, cw.value, ch.value);
      ctx.value.font = '600 36px Inter';
      ctx.value.fillText(
        percText.value,
        cw.value * 0.52,
        ch.value * 0.5 + 5,
        cw.value + 12
      );
      ctx.value.font = '600 15px Inter';
      ctx.value.fillText(
        compText.value,
        cw.value * 0.52,
        ch.value * 0.5 + 25,
        cw.value + 12
      );
    };

    return {
      root,
      currentProgress
    };
  },
});
</script>
