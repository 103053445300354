<template>
  <div>
    <h2 class="mt-2 mt-md-2 mt-md-0">How are we doing?</h2>
    <span class="row" v-if="challenge.challenge_type == 'distance'">
      <span class="athlete-name p-1 col-4">Distance</span>
      <h3 class="p-1 text-right text-muted col-8 mb-0 mt-0"><strong>{{ numberWithCommas(parseFloat(totalMi).toFixed(2)) }}</strong> of <strong>{{ numberWithCommas(challenge.target) }}</strong> miles</h3>
    </span>
    <div class="bar col-12 pl-0 w-100" v-if="challenge.challenge_type == 'distance'">
      <div class="bar-width d-block" :style="{ width: progress + '%' }"></div>
      <span v-bind:class="['bar-target', (onTarget) ? 'on-target' : 'off-target']">
        {{ (onTarget) ? `On target` : `Keep trying` }}
      </span>
      <span class="bar-digits col-2 text-right">
        {{ parseFloat(progress).toFixed(2) }}%
      </span>
    </div>

    <span class="row mt-3" v-if="challenge.challenge_type == 'elevation'">
      <span class="athlete-name p-1 col-4">Elevation</span>
      <h3 class="p-1 text-right text-muted col-8 mb-0 mt-0"><strong>{{ numberWithCommas(parseFloat(totalEl).toFixed(0)) }}</strong> of <strong>{{ numberWithCommas(challenge.target) }}</strong> feet</h3>
    </span>
    <div class="bar col-12 pl-0 w-100" v-if="challenge.challenge_type == 'elevation'">
      <div class="bar-width d-block" :style="{ width: progress + '%' }"></div>
      <span class="bar-digits col-2 text-right">
        {{ parseFloat(progress).toFixed(2) }}%
      </span>
    </div>

    <span class="row mt-3" v-if="challenge.challenge_type == 'time'">
      <span class="athlete-name p-1 col-4">Activity time</span>
      <h3 class="p-1 text-right text-muted col-8 mb-0 mt-0"><strong>{{ numberWithCommas(parseFloat(totalTi).toFixed(0)) }}</strong> of <strong>{{ numberWithCommas(challenge.target) }}</strong> minutes</h3>
    </span>
    <div class="bar col-12 pl-0 w-100" v-if="challenge.challenge_type == 'time'">
      <div class="bar-width d-block" :style="{ width: progress + '%' }"></div>
      <span class="bar-digits col-2 text-right">
        {{ parseFloat(progress).toFixed(2) }}%
      </span>
    </div>
  </div>
</template>

<script>

  import 'lodash';
  import axios from "axios";

  let totalM = 0
  let totalE = 0
  let totalT = 0
  let mi = 1609.344
  let ft = 3.28084
  let today=new Date()
  let endofyear=new Date(today.getFullYear(), 11, 31)
  let one_day=1000*60*60*24
  let days_left=Math.ceil((endofyear.getTime()-today.getTime())/(one_day))
  let perc_days_left=(days_left/365)*100

  export default {
    name: 'challenge-progress',
    data() {
      return {
        activities: this.$attrs.activities,
        challenge: (this.$attrs.challenge) ? this.$attrs.challenge : null,
        weekActivities: this.$attrs.week,
        athleteTotals: [],
        athleteTotalsWeek: [],
        athleteTotalsActivty: [],
        athleteTotalsActivtyWeek: [],
        longestActivity: {},
        longestActivityWeek: {},
        progress: 0,
        progressUpEverest: 0,
        onTarget: false,
        totalMi: 0,
        totalEl: 0,
        totalTi: 0,
        showOverallStats: false,
        allTimeStats: true
      };
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize() {
        for (let activity in this.$attrs.activities) {
          if (this.$attrs.activities[activity].override_distance) {
            this.$attrs.activities[activity].distance = this.$attrs.activities[activity].override_distance
          }
        }
        for (let activity in this.$attrs.week) {
          if (this.$attrs.week[activity].override_distance) {
            this.$attrs.week[activity].distance = this.$attrs.week[activity].override_distance
          }
        }

        // let _athleteTotalsActivty = _.countBy(this.$attrs.activities, 'athlete')
        this.athleteTotalsActivty = _(this.$attrs.activities)
            .countBy('athlete')
            .map((val, key) => {
              return { name: key, count: val }
            })
            .sortBy('count')
            .reverse()
            .value();

        this.athleteTotalsActivtyWeek = _(this.$attrs.week)
            .countBy('athlete')
            .map((val, key) => {
              return { name: key, count: val }
            })
            .sortBy('count')
            .reverse()
            .value();

        this.athleteTotalsWeek = _(this.$attrs.week)
          .groupBy('athlete')
          .map((athlete) => ({
            athlete: athlete[0].athlete,
            distance: _.sumBy(athlete, item => Number(item.distance)),
            elevation_gain: _.sumBy(athlete, item => Number(item.elevation_gain)),
            time: _.sumBy(athlete, item => Number(item.moving_time))
          }))
          .value()

        this.athleteTotals = _(this.$attrs.activities)
          .groupBy('athlete')
          .map((athlete) => ({
            athlete: athlete[0].athlete,
            distance: _.sumBy(athlete, item => Number(item.distance)),
            elevation_gain: _.sumBy(athlete, item => Number(item.elevation_gain)),
            time: _.sumBy(athlete, item => Number(item.moving_time))
          }))
          .value()

        for (let activity in this.activities) {
          totalM += parseFloat(this.activities[activity].distance)
          totalT += parseFloat(this.activities[activity].moving_time)
          if (this.activities[activity].elevation_gain != '') {
            totalE += parseFloat(this.activities[activity].elevation_gain)
          }
        }
        this.totalMi = totalM/mi
        this.totalEl = totalE*ft
        this.totalTi = totalT/60
				switch(this.challenge.challenge_type) {
					case 'distance':
						this.progress = (this.totalMi/this.challenge.target)*100
						break;
					case 'elevation':
						this.progress = (this.totalEl/this.challenge.target)*100
						break;
					case 'time':
						this.progress = (this.totalTi/this.challenge.target)*100
						break;
				}
        if(perc_days_left>(100-this.progress)) {
          this.onTarget = true
        }
      },
      numberWithCommas(n) {
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
</script>
<style lang="scss" scoped>
h2.active {
  font-weight: 900;
  color: #174776
}

.bar {
  position: relative;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  .bar-name {
    position: absolute;
    left: 50%;
    top: 12px;
    text-transform: capitalize;
    color: #243546;
    font-weight: 700;
  }
  .bar-width {
    background-color: #174776;
    height: 40px;
    border-radius: 25px;
  }
}

.bar-target {
  position: absolute;
  left: 15px;
  top: 7px;
  text-transform: capitalize;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;

  &.on-target {
    color: #02a517
  }
}

.bar-digits {
  position: absolute;
  right: 15px;
  top: 7px;
  text-transform: capitalize;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;
}

.stats-btn {
  cursor: pointer;
}
</style>
