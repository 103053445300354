<template>
  <div class="w-100">
    <h2 class="mt-0">Activities</h2>
    <ul class="list">
      <div class="row d-none d-md-flex border-bottom">
        <span 
          v-for="column in columns" 
          :key="column.name"
          :style="getColumnWidth(column.name)" 
          v-bind:class="(column.name == 'distance') ? 'text-md-right' : 'text-left'"
          >
          <button
            class="btn btn-tertiary__trans select pl-3 pr-3 pt-2 pb-2 text-capitalize text-left"
            v-if="column != ''"
            v-on:click="sortByColumn(column)">
              {{ (column.name.split('_')[1]) ? column.name.split('_')[1] : column.name }}
            </button>
        </span>
      </div>
      <li 
        v-for="index in activitiesToShow" 
        :key="index"
        class="list-group-item d-none d-md-flex mb-1 p-0 flex-wrap border-bottom"
      >
        <a
          class="w-100 row" 
          @click="e => e.target.closest('.list-group-item').classList.toggle('active')">
          <div
            v-for="column in columns"
            :key="column.name"
            v-bind:style="getColumnWidth(column.name)"
            :class="['pl-3 pr-3 pt-2 pb-2 activity-name', (column.name === 'distance' ? 'font-weight-bold text-right' : '' )]"
          >
            <span v-if="column.name == 'activity_type'" class="text-capitalize">
              {{ activities[index - 1][column.name] }}
            </span>
            <span v-else-if="column.name == 'distance'">
              {{ parseFloat(activities[index - 1][column.name]/1609.344).toFixed(2) }}<small>mi</small>
            </span>
            <span class="athlete-name" v-else-if="column.name == 'user'">
              {{ activities[index - 1][column.name].username }}
            </span>
            <span class="text-muted" v-else>
              {{ activities[index - 1][column.name] }}
            </span>
          </div>
          <span class="row w-100 pl-3 pr-2 pb-2 align-items-center justify-content-between stats">
            <small>{{ new Date(activities[index - 1].start_date).toLocaleDateString() }}</small>
            <span class="row">
              <span class="btn btn__pill row align-items-center">
                <svg-icon icon="mountains" fillColor="#6E7C8" width="20px" height="20px" strokeColor="none"></svg-icon>
                <span class="pl-1">{{ parseFloat(activities[index - 1].elevation_gain*3.28084).toFixed(0) }}<small>ft</small></span>
              </span>
              <span class="btn btn__pill row align-items-center ml-2">
                <svg-icon icon="time" fillColor="#6E7C8" width="20px" height="20px" strokeColor="none"></svg-icon>
                <span class="pl-1">{{ secondsToHms(activities[index - 1].moving_time) }}</span>
              </span>
            </span>
          </span>
        </a>
      </li>
      <li 
        v-for="index in activitiesToShow" 
        :key="index"
        class="list-group-item d-flex d-md-none mb-1 flex-wrap border-bottom"
      >
        <a
          class="w-100 row" 
          @click="e => e.target.closest('.list-group-item').classList.toggle('active')"
        >
          <span class="athlete-name col-8 p-0">{{ activities[index - 1].user.username }}</span>
          <span class="col-4 p-0 justify-content-end row">
            <strong>{{ parseFloat(activities[index - 1].distance/1609.344).toFixed(2) }}</strong><small>mi</small>
          </span>
          <span class="col-10 p-0 mt-2 text-muted activity-name">{{ activities[index - 1].activity_name }}</span>
          <span class="col-2 mt-1 p-0">
            <svg-icon v-bind:icon="activities[index - 1].activity_type" width="14px" height="14px" fillColor="#174776" strokeColor="none" classes="text-right"></svg-icon>
          </span>
          <span class="row w-100 pt-2 align-items-center stats">
            <small class="mb-2">{{ new Date(activities[index - 1].start_date).toLocaleDateString() }}</small>
            <span class="w-100 row justify-content-between">
              <span class="btn btn__pill row align-items-center">
                <svg-icon icon="mountains" fillColor="#6E7C8" width="20px" height="20px" strokeColor="none"></svg-icon>
                <span class="pl-1">{{ parseFloat(activities[index - 1].elevation_gain*3.28084).toFixed(0) }}<small>ft</small></span>
              </span>
              <span class="btn btn__pill row align-items-center ml-2">
                <svg-icon icon="time" fillColor="#6E7C8" width="20px" height="20px" strokeColor="none"></svg-icon>
                <span class="pl-1">{{ secondsToHms(activities[index - 1].moving_time) }}</span>
              </span>
            </span>
          </span>
        </a>
      </li>
    </ul>
    <span class="w-100 text-center d-block" v-if="activities.length > activitiesToShow">
      <button class="btn btn-tertiary__trans" @click="showMore()">SHOW MORE</button>
    </span>
  </div>
</template>

<script>
  import { secondsToHms } from "../mixins/helpers";

  let mi = 1609.344
  export default {

    name: 'recent-activities',
    data() {
      return {
        activities: this.$attrs.activities,
        columns: this.$attrs.columns,
        activitiesToShow: (this.$attrs.activities.length < 10) ? this.$attrs.activities.length : 10
      };
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize() {
        for (let activity in this.$attrs.activities) {
          if (this.$attrs.activities[activity].override_distance) {
            this.$attrs.activities[activity].distance = this.$attrs.activities[activity].override_distance
          }
        }
      },
      showMore: function() {
        if (this.activitiesToShow+5 > this.activities.length) {
          this.activitiesToShow = this.activities.length
        }else {
          this.activitiesToShow += 5
        }
      },
      getColumnWidth: function(key) {
        let count = 0
        for (let colSpan in this.columns) {
          count += this.columns[colSpan].span
        }

        let _width = (100/count);
        for (let colSpan in this.columns) {
          if (this.columns[colSpan].name === key) {
            _width = _width * this.columns[colSpan].span
          }
        }
        return { width: _width + '%' };
      },
      sortByColumn: function(column) {
        let sortOrder = -1
        let targetElm = event.currentTarget
        let sortButtons = document.querySelectorAll('.list-group .btn.select');

        sortButtons.forEach((btn) => {
          if (btn != targetElm) {
            btn.classList.remove('asc','desc')
        	}
        })
        if (targetElm.classList.contains('asc') || targetElm.classList.contains('desc')) {
          if (targetElm.classList.contains('asc')) {
            targetElm.classList.remove('asc');
            targetElm.classList.add('desc');
            sortOrder = 1
          }else if (targetElm.classList.contains('desc')) {
            targetElm.classList.remove('desc');
            targetElm.classList.add('asc');
            sortOrder = -1
          }
        }else{
          targetElm.classList.add('asc');
        }
        this.activities = this.activities.sort(this.dynamicSort(column.name, sortOrder))
      },
      dynamicSort: function(property, sortOrder) {
        if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
        }
        return function (a,b) {
          let testFloat = parseFloat(a[property])
          let result
          if (isNaN(testFloat)){
            result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
          }else{
            let _a = parseFloat(a[property])
            let _b = parseFloat(b[property])
            result = (_a < _b) ? -1 : (_a > _b) ? 1 : 0;
          }
          return result * sortOrder;
        }
      },
      secondsToHms: function(d) {
        return secondsToHms(d)
      }
    }
  }
</script>
<style lang="scss" scoped>
  .athlete-name {
    text-transform: capitalize;
  }
  .activity-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .list-group-item {
    cursor: pointer;
  }
  .list-group-item:not(.active) .stats{
    display: none;
  }
</style>
