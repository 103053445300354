<template>
  <h2 class="mt-0">Your progress</h2>
  <h3 class="athlete-goal p-1 text-right text-muted col-12 mb-0 mt-0 row justify-content-between">
    {{ user.username }}
    <span>
      <strong>
        {{ doGetTotal() }}</strong> out of <strong>{{ (challenge.challenge_type == 'elevation') ? doNumberWithCommas(goal) : goal }}
      </strong> 
      {{ getUnit(challenge.challenge_type) }}
    </span>
  </h3>
  <span class="row bar col-12 pl-0 pr-0">
    <span class="bar-deets">{{ (progress >= 100) ? "100%" : parseFloat(progress).toFixed(2) + "%" }}</span>
    <span v-bind:class="['bar-target', (onTarget) ? 'on-target' : 'off-target']" v-if="progress < 100">
      {{ (onTarget) ? `On target` : `Keep trying` }}
    </span>
    <span class="bar-width d-block" v-bind:style="{ width: progress + '%' }"></span>
  </span>
  <span class="row mt-2 w-100 d-flex justify-content-end">
    <span v-bind:class="['mb-2 justify-content-end', (!hasGoal ? 'mb-sm-2 card card__red w-100' : 'row mb-sm-0' )]" v-if="showUpdateForm">
      <h2 v-if="hasGoal" class="text-right d-none d-sm-block mt-1 mb-0 mr-1">Goal:</h2>
      <span v-show="!hasGoal">
        <h2 class="m-0">Your goal</h2>
        <p class="mb-2">You can set your own personal goal for this challenge here. Make it a challenge, but achievable. Once you're happy click the update button.</p>
      </span>
      <input id="goal" type="number" v-bind:value="goal">
      <button class="btn btn-primary__slim ml-2" v-on:click="updateGoal('goal')">UPDATE</button>
    </span>
    <button class="btn btn-primary__white mb-2 mb-sm-0" @click="showUpdateForm = true" v-if="!showUpdateForm">CHANGE GOAL</button>
  </span> 
</template>

<script>
  import axios from "axios";
  import { getTotal, getProgress, athleteTotals, getUnit, numberWithCommas } from "../mixins/helpers";
  import { defineComponent, ref, onMounted } from 'vue';

  export default defineComponent({
    name: 'my-challenge-progress',
    props: {
      user: {
        type: Object,
        required: true
      },
      challenge: {
        type: Object,
        required: true
      },
      activities: {
        type: Array,
        required: true
      },
      goal: {
        type: Number,
        required: false
      },
      hasGoal: {
        type: Boolean,
        required: false
      },
    },

    setup(props) {
      const athleteTotalsAll = ref(athleteTotals(props.activities));
      const total = ref(getTotal(athleteTotalsAll.value[0], props.challenge.challenge_type));
      const progress = ref(0);
      const showUpdateForm = ref((props.hasGoal.value) ? false : true);
      const today = new Date()
      const one_day = 1000*60*60*24
      const daysLeft = ref(Math.ceil((new Date(props.challenge.ends).getTime()-today.getTime())/(one_day)));
      const percDaysLeft = ref(daysLeft.value/365*100);
      const onTarget = ref(percDaysLeft.value>(100-progress.value))

      const updateGoal = (elm) => {
        let value = document.getElementById(elm).value
        return axios
        .post("/update-goal", {
          challenge_id: props.challenge.id,
          user_id: props.user.id,
          goal: value
        })
        .then(response => {
          location.reload();
        })
        .catch(e => {
           console.log(e);
        });
      }

      const doGetTotal = () => {
        return getTotal(athleteTotalsAll.value[0], props.challenge.challenge_type)
      }

      const doNumberWithCommas = (goal) => {
        return numberWithCommas(goal)
      }

      onMounted(() => {
        let target = props.goal ? props.goal : props.challenge.default_goal;
        progress.value = getProgress(target, props.challenge.challenge_type, athleteTotalsAll.value[0]);
      });

      return {
        showUpdateForm,
        total,
        progress,
        getUnit,
        updateGoal,
        doNumberWithCommas,
        onTarget,
        doGetTotal
      }
    },
  });
</script>

<style lang="scss" scoped>
.athlete-name {
  text-transform: capitalize;
}

.bar {
  position: relative;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  .bar-name, .bar-miles {
    position: absolute;
    left: 48%;
    top: 12px;
    text-transform: capitalize;
    color: #243546;
    font-weight: 700;
  }
  .bar-deets {
    position: absolute;
    right: 2px;
    top: 3px;
    text-transform: capitalize;
    color: #174776;
    display: inline-block;
    background: rgba(255, 255, 255, 0.8);
    padding: 6px;
    border-radius: 16px;
    font-size: 14px;
  }
  .bar-name {
    right: auto;
    left: 3px;
    top: 6px;
    font-weight: 500;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d3e3ef
  }
  .bar-miles {
    left: auto;
    right: 3px;
    font-weight: 500;
  }
  .bar-width {
    background-color: #174776;
    height: 31px;
    border-radius: 25px;
  }
}

.bar-target {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;

  &.on-target {
    color: #02a517
  }
}

.bar-digits {
  color: #243546;
  font-weight: 700;
  height: 40px;
  padding-top: 12px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  color: white;
}

.mvp {
  color: #02a517;
}
</style>