<template>
  <div>
    <div class="mobile-header d-flex d-md-none row">
      <button ref="toggle-nav" class="btn btn-tertiary__slim d-flex" v-on:click="toggleNav()">
        <svg-icon icon="menu" width="18px" height="18px" fillColor="#174776" strokeColor="none" classes="text-right"></svg-icon>
      </button>
      <h1 class="pt-2">{{ title }}</h1>
    </div>
    <nav id="nav-drawer"
        class="nav-drawer d-none d-md-flex pt-4 pt-md-0"
        v-closable="{
          exclude: ['toggle-nav'],
          handler: 'onClose'
        }"
      >
      <a href="/" :title="title">
        <h2 class="p-3 d-block d-sm-none d-lg-block">{{ title }}</h2>
        <svg-icon icon="home" fillColor="#174776" strokeColor="none" classes="p-2 d-none d-sm-block d-lg-none"></svg-icon>
      </a>
      <ul class="nav-user mb-2" v-if="user.id">
        <hr />
        <li class="nav-li has-submenu row">
          <svg-icon icon="admin" fillColor="#174776" strokeColor="none"></svg-icon>
          <a href="/admin" title="Edit" class="d-flex d-sm-none d-lg-flex flex-column justify-content-center"><span class="text-uppercase pl-2">{{ user.username }}</span></a>
          <div class="subnav p-2">
            <h2 class="mt-0 pb-1">{{ user.username }}</h2>
            <div class="row">
              <div class="col-6 pl-0 border-right" v-if="user.role === 'admin'">
                <h3>Admin</h3>
                <ul>
                  <li class="subnav-li">
                    <a href="/admin/users" title="Primary" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="admin" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Users</span> 
                    </a>
                  </li>
                  <li class="subnav-li">
                    <a href="/admin/activities" title="Primary" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="dash" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Activities</span>
                    </a>
                  </li>
                  <li class="subnav-li">
                    <a href="/admin/challenges" title="Primary" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="dash" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Challenges</span>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-6 pr-0">
                <h3>Account</h3>
                <ul>
                  <li class="subnav-li">
                    <a href="/users/edit" title="Home" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="account" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Account</span>
                    </a>
                  </li>
                  <li class="subnav-li">
                    <a href="/users/sign_out" title="Home" data-method="delete" class="btn btn-tertiary__icon d-flex">
                      <svg-icon icon="logout" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
                      <span class="btn-tertiary__text">Logout</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
        <hr />
      </ul>
      <ul class="nav-ul mb-2">
        <li class="nav-li row">
          <a :href="'/challenges'" title="Challenges" class="w-100">
            <svg-icon icon="dash" classes="pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
            <span class="nav-li__text">Challenges</span>
          </a>
        </li>
        <li class="nav-li row">
          <a :href="'/'" title="My progress" class="w-100">
            <svg-icon icon="face" classes="pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
            <span class="nav-li__text">My progress</span>
          </a>
        </li>
        <li 
          class="nav-li row" 
          v-for="(page, index) in pages"
          :key="index"
          >
          <a :href="'/' + page.slug" v-bind:title="page.title" class="w-100">
            <svg-icon icon="page" classes="pt-0 pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none" v-if="page.icon === ''"></svg-icon>
            <svg-icon v-bind:icon="page.icon" classes="pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none" v-else></svg-icon>
            <span class="pt-1 nav-li__text">{{ page.title }}</span>
          </a>
        </li>
      </ul>
      <span class="spacer"></span>
      <ul class="nav-ul mb-2">
        <li class="nav-li row" v-if="!user.id">
          <a href="/users/sign_in" class="w-100">
            <svg-icon icon="enter" classes="pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none"></svg-icon>
            <span class="pt-1 nav-li__text">Login</span>
          </a>
        </li>
        <li class="nav-li row" v-else>
          <a href="/update-activities" id="sync-btn" class="w-100" data-remote="true" v-on:click="isClicked = true">
            <span :class="['p-0',{ spinning: isClicked }]" width="30" height="30">
              <svg-icon icon="sync" classes="pb-0 pl-1 pr-1" fillColor="#174776" strokeColor="none" width="30px" height="30px"></svg-icon>
            </span>
            <span class="nav-li__text">Sync Activities</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>
 <!--  -->
<script>

  import SvgIcon from './svg-icon.vue'
  import { Closable } from '../directives/closable.js';

  export default {
    name: 'nav-drawer',
    data () {
      return {
        pages: this.$attrs.pages,
        user: this.$attrs.user,
        title: this.$attrs.title,
        isClicked: false
      }
    },
    directives: {
      Closable
    },
    components: {
      SvgIcon
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize() {
        // Loop through the options and override the defaults
        for (let k in this.options) {
          if (this.hasOwnProperty(k)) {
            this[k] = this.options[k];
          }
        }

        let syncBtn = document.getElementById('sync-btn')
        if (syncBtn) {
          syncBtn.addEventListener('ajax:success', function(){
            location.reload()
          })
        }
      },
      toggleNav() {
        let nav = document.getElementById('nav-drawer')
        if (nav.classList.contains('d-none')) {
          nav.classList.remove('d-none')
        }else{
          nav.classList.add('d-none')
        }
      },
      onClose: function(){
        let nav = document.getElementById('nav-drawer')
        nav.classList.add('d-none')
      },
    }
  }
</script>

<style lang="scss" scoped>
  .spinning {
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
  }

  @keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(-360deg);
    }
}
</style>
