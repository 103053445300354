<template>
  <div>

  </div>
</template>

<script>
  let totalM = 0
  let mi = 1609.344
  let today=new Date()
  let endofyear=new Date(today.getFullYear(), 11, 31)
  let one_day=1000*60*60*24
  let days_left=Math.ceil((endofyear.getTime()-today.getTime())/(one_day))
  let perc_days_left=(days_left/365)*100

  export default {

    name: 'stats',
    data() {
      return {
        activities: this.$attrs.activities,
        progress: 0,
        onTarget: false,
        totalMi: 0
      };
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize() {
        for (let activity in this.activities) {
          totalM += parseFloat(this.activities[activity].distance)
        }
        this.totalMi = totalM/mi
        this.progress = (this.totalMi/3000)*100
        if(perc_days_left>(100-this.progress)) {
          this.onTarget = true
        }
      }
    }
  }
</script>
<style lang="scss" scoped>

</style>
