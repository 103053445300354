<template>
  <div>
    <h2 class="mb-1 mt-0" v-if="currentUser">Welcome {{ currentUser.username }}, here's your stats:</h2>
    <div class="row mt-sm-3 mb-4 text-center">
      <span class="col-12 col-sm-4 mb-2 mb-sm-0 p-0">
        <svg-icon icon="distance" fillColor="#174776" width="50px" height="50px" strokeColor="none"></svg-icon>
        <p class="color-black">Distance travelled:</p>
        <strong>{{ parseFloat(totalMi).toFixed(2) }} miles</strong>
      </span>
      <span class="col-12 col-sm-4 mb-2 mb-sm-0 p-0 border-left border-right">
        <svg-icon icon="mountains" fillColor="#174776" width="50px" height="50px" strokeColor="none"></svg-icon>
        <p class="color-black">Height climbed:</p>
        <strong>{{ numberWithCommas(parseFloat(totalEl).toFixed(0)) }} feet</strong>
      </span>
      <span class="col-12 col-sm-4 mb-2 mb-sm-0 p-0">
        <svg-icon icon="time" fillColor="#174776" width="50px" height="50px" strokeColor="none"></svg-icon>
        <p class="color-black">Time spent:</p>
        <strong>{{ totalTi }}</strong>
      </span>
    </div>

    <span class="row mt-sm-3" v-if="showDistProg">
      <span class="pt-1 pb-1 pl-0 col-12 col-sm-8 text-sm-left">Lands End to John O'Groats <small class="text-muted">{{ quotientMi + getOrdinal(quotientMi) }} lap</small></span>
      <h3 class="p-1 text-right text-muted col-12 col-sm-4 mb-0 mt-0"><strong>{{ parseFloat(remainderMi).toFixed(2) }}</strong> of <strong>980</strong> miles</h3>
    </span>
    <div class="bar col-12 pl-0 pr-0 w-100" v-if="showDistProg">
      <div class="bar-width d-block" :style="{ width: progress + '%' }"></div>
      <span class="bar-target">
        Road to {{ currentStage }}
      </span>
      <span class="bar-digits text-right">
        {{ parseFloat(progress).toFixed(2) }}%
      </span>
    </div>

    <span class="row mt-3" v-if="showElvProg">
      <span class="pt-1 pb-1 pl-0 col-12 col-sm-8 text-sm-left">Climbing Everest <small class="text-muted">{{ quotientEl + getOrdinal(quotientEl) }} ascent</small></span>
      <h3 class="p-1 text-right text-muted col-12 col-sm-4 mb-0 mt-0"><strong>{{ numberWithCommas(parseFloat(remainderEl).toFixed(0)) }}</strong> of <strong>29,030</strong> feet</h3>
    </span>
    <div class="bar col-12 pl-0 pr-0 w-100" v-if="showElvProg">
      <div class="bar-width d-block" :style="{ width: progressUpEverest + '%' }"></div>
      <span class="bar-target">
        {{ currentCamp }}
      </span>
      <span class="bar-digits text-right">
        {{ parseFloat(progressUpEverest).toFixed(2) }}%
      </span>
    </div>
  </div>
</template>

<script>
  import 'lodash';

  let totalM = 0
  let totalE = 0
  let totalT = 0
  let mi = 1609.344
  let ft = 3.28084

  const camps = [
    {
      name: "Travelling to Katmandu", 
      alt: 4600
    },
    {
      name: "Treking to Base Camp",
      alt: 17700
    },
    {
      name: "Climbing to Camp I",
      alt: 19900
    },
    {
      name: "Hiking to Camp II",
      alt: 21300
    },
    {
      name: "Struggling to Camp III",
      alt: 24500
    },
    {
      name: "Camp IV is in sight",
      alt: 26000
    },
    {
      name: "Time to summit!",
      alt: 29030
    }
  ]

  const stages = [
    {
      name: "Okehampton",
      alt: 105
    },
    {
      name: "Bath",
      alt: 215
    },
    {
      name: "Ludlow",
      alt: 316
    },
    {
      name: "Haydock",
      alt: 423
    },
    {
      name: "Carlisle",
      alt: 553
    },
    {
      name: "Edinburgh",
      alt: 659
    },
    {
      name: "Strathdon",
      alt: 771
    },
    {
      name: "Kyle of Sutherland",
      alt: 890
    },
    {
      name: "John O'Groats!",
      alt: 980
    }
  ]

  export default {
    name: 'overall-progress',
    data() {
      return {
        activities: this.$attrs.activities,
        weekActivities: this.$attrs.week,
        currentUser: this.$attrs.user,
        progress: 0,
        progressUpEverest: 0,
        quotientMi: 0,
        remainderMi: 0,
        quotientEl: 0,
        remainderEl: 0,
        currentCamp: "Travelling to Kathmandu",
        currentStage: "Okehampton",
        showDistProg: true,
        showElvProg: true,
        totalMi: 0,
        totalEl: 0,
        totalTi: 0
      };
    },
    mounted() {
      this.initialize()
    },
    methods: {
      initialize() {
        for (let activity in this.$attrs.activities) {
          if (this.$attrs.activities[activity].override_distance) {
            this.$attrs.activities[activity].distance = this.$attrs.activities[activity].override_distance
          }
        }
        for (let activity in this.$attrs.week) {
          if (this.$attrs.week[activity].override_distance) {
            this.$attrs.week[activity].distance = this.$attrs.week[activity].override_distance
          }
        }
        for (let activity in this.activities) {
          totalM += parseFloat(this.activities[activity].distance)
          totalT += parseFloat(this.activities[activity].moving_time)
          if (this.activities[activity].elevation_gain != '') {
            totalE += parseFloat(this.activities[activity].elevation_gain)
          }
        }
        this.totalMi = totalM/mi
        this.totalEl = totalE*ft
        this.totalTi = this.secondsToHms(totalT)

        this.quotientMi = Math.floor(this.totalMi/980) + 1
        this.remainderMi =  this.totalMi % 980
        this.progress = (this.remainderMi/980)*100

        this.quotientEl = Math.floor(this.totalEl/29030) + 1
        this.remainderEl =  this.totalEl % 29030
        this.progressUpEverest = (this.remainderEl/29030)*100
        for (let camp in camps) {
          if (this.remainderEl > camps[camp].alt) {
            this.currentCamp = camps[parseInt(camp)+1].name
          }
        }
        for (let stage in stages) {
          if (this.remainderMi > stages[stage].alt) {
            this.currentStage = stages[parseInt(stage)+1].name
          }
        }
      },
      getOrdinal(n) {
        if (n > 3 && n < 21) return 'th';
        switch (n % 10) {
          case 1:  return "st";
          case 2:  return "nd";
          case 3:  return "rd";
          default: return "th";
        }
      },
      numberWithCommas(n) {
        return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
      secondsToHms(d) {
          d = Number(d);
          var h = Math.floor(d / 3600);
          var m = Math.floor(d % 3600 / 60);
          var s = Math.floor(d % 3600 % 60);

          var hDisplay = h > 0 ? h + (h == 1 ? "h:" : "h:") : "";
          var mDisplay = m > 0 ? m + (m == 1 ? "m:" : "m:") : "";
          var sDisplay = s > 0 ? s + (s == 1 ? "s" : "s") : "";
          return hDisplay + mDisplay + sDisplay;
      }
    }
  }
</script>
<style lang="scss" scoped>
h2.active {
  font-weight: 900;
  color: #174776
}

.bar {
  position: relative;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  .bar-name {
    position: absolute;
    left: 50%;
    top: 12px;
    text-transform: capitalize;
    color: #243546;
    font-weight: 700;
  }
  .bar-width {
    background-color: #174776;
    height: 32px;
    border-radius: 25px;
  }
}

.bar-target {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;

  &.on-target {
    color: #02a517
  }
}

.bar-digits {
  position: absolute;
  right: 15px;
  top: 7px;
  text-transform: capitalize;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;
}

.stats-btn {
  cursor: pointer;
}
</style>
