import { createApp } from 'vue/dist/vue.esm-bundler.js';

import * as components from '../components'

import '../components/index.js';
import '../stylesheets/index.js';

document.addEventListener('DOMContentLoaded', () => {
  let appContainers = document.getElementsByClassName("vue-container")
  if (appContainers.length > 0) {
    for (let vc of appContainers) {
      const app = createApp({})
      for (const componentName in components) {
        const component = components[componentName]
        app.component(component.name, component)
      }
      app.mount(vc)
    }
  }
})
