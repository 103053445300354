<template>
  <div>
    <span class="d-flex row mt-3 justify-content-between">
      <h2 class="mt-0 mb-0 col-5 pl-0 pt-1">Leaderboard</h2>
      <a href="/challenges" class="btn btn-primary__white" v-if="!user.id">My progress</a>
    </span>
    <ul 
      v-if="Object.keys(athleteTotalsGrouped).length > 1"
      class="row mt-2"
    >
      <li><button v-on:click="switchFilter($event, 'all')" class="active btn btn-filter">Overall</button></li>
      <li 
        v-for="key in Object.keys(athleteTotalsGrouped)" 
        :key="key"
      >
        <button v-on:click="switchFilter($event, key)" class="text-capitalize btn btn-filter">{{ key }}</button>
      </li>
    </ul>
    <ul class="bar-list mt-2">
      <li 
        v-for="(item, index) in totals.slice(0, 10)"
        :key="item.athlete"
        class="row mb-3" 
      >
        <span class="w-100">
          <h3 class="athlete-goal p-1 text-right text-muted col-12 mb-0 mt-0 row justify-content-between">
            <span class="row">
              {{ item.athlete }}
              <svg-icon icon="medal" fillColor="gold" width="20px" height="20px" classes="pl-1" v-if="index === 0"></svg-icon>
              <svg-icon icon="medal" fillColor="silver" width="20px" height="20px" classes="pl-1" v-if="index === 1"></svg-icon>
              <svg-icon icon="medal" fillColor="peru" width="20px" height="20px" classes="pl-1" v-if="index === 2"></svg-icon>
            </span>
            <span>
              <strong>
                {{ getTotal(item) }}</strong> out of <strong>{{ (challenge.challenge_type == 'elevation') ? numberWithCommas(item.goal) : item.goal }}
              </strong> 
              {{ getUnit() }}
            </span>
          </h3>
          <span class="row bar col-12 pl-0 pr-0">
            <span class="bar-deets">{{ (item.progress >= 100) ? "100%" : parseFloat(item.progress).toFixed(2) + "%" }}</span>
            <span v-bind:class="['bar-target', (item.onTarget) ? 'on-target' : 'off-target']" v-if="item.progress < 100">
              {{ (item.onTarget) ? `On target` : `Keep trying` }}
            </span>
            <span class="bar-width d-block" v-bind:style="{ width: item.progress + '%' }"></span>
          </span>
          <!--span class="row mt-2 w-100 d-flex justify-content-end">
            <span class="d-block pr-0 ml-2" v-if="!showMVPStats">
              <button class="btn btn-primary__white" @click="showMVPStats = true">ATHLETES</button>
            </span>
            <span class="d-block pr-0 ml-2" v-if="showMVPStats">
              <button class="btn btn-primary__white" @click="showMVPStats = false">IMPROVEMENT</button>
            </span>
          </span-->           
        </span>           
      </li>
    </ul>
    <!--span  v-if="!showMVPStats">
      <h3 class="mt-2">Most improved (last 7 days)</h3>
      <ul class="list mt-0 mb-3">
        <li 
          class="list-group-item mb-1 p-0 d-flex flex-wrap" 
          v-for="(mvp, index) in mvps" 
          :key="index"
        >
          <span v-if="index < 5">
            <div class="pl-3 pr-3 pt-2 pb-2 col-6 text-capitalize">
              {{ mvp.athlete }}
            </div>
            <div class="pl-3 pr-3 pt-2 pb-2 col-6 text-right">
              <span class="mvp">+{{ parseFloat(mvp.progress).toFixed(2) }}%</span>
            </div>
          </span>
        </li>
      </ul>
    </span>
    <ul class="bar-list mt-2" v-if="showMVPStats">
      <li 
        v-for="item in athleteTotals" 
        :key="item.athlete"
        class="row mb-3" 
      > 
        <span v-if="item.athlete != user.athlete">
          <span class="athlete-name p-1 col-4">{{ item.athlete.split(' ')[0] }}</span>
          <h3 class="athlete-goal p-1 text-right text-muted col-8 mb-0 mt-0"><strong>{{ getTotal(item) }}</strong> out of <strong id="item-goal">{{ item.goal }}</strong> {{ getUnit() }}</h3>
          <span class="bar col-12 pl-0 pr-0">
            <span class="bar-deets">{{ parseFloat(item.progress).toFixed(2) }}%</span>
            <span v-bind:class="['bar-target', (item.onTarget) ? 'on-target' : 'off-target']">
              {{ (item.onTarget) ? `On target` : `Keep trying` }}
            </span>
            <span class="bar-width d-block" :style="{ width: item.progress + '%' }"></span>
          </span>
        </span>
      </li>
    </ul-->
  </div>
</template>

<script>
  import 'lodash';
  import { athleteTotals, sortBy, groupBy, getUnit, getTotal, numberWithCommas } from "../mixins/helpers";

  let mi = 1609.344
  let ft = 3.28084
  let today=new Date()
  let one_day=1000*60*60*24 
  let progress = 0
  let totalMi = 0
  let totalEl = 0
  let totalTi = 0

  export default {
    name: 'athlete-progress',
    data() {
      return {
        activities: this.$attrs.activities,
        activitesGrouped: this.$attrs.activities,
        athletes: this.$attrs.athletes,
        weekActivities: this.$attrs.week,
        athleteGoals: this.$attrs.goals,
        challenge: (this.$attrs.challenge) ? this.$attrs.challenge : null,
        user: this.$attrs.user,
        athleteTotals: [],
        athleteTotalsGrouped: {},
        mvps: [],
        showMVPStats: false,
        hasGoal: this.$attrs.goal,
        showUpdateForm: (this.$attrs.goal) ? false : true,
        daysLeft: Math.ceil((new Date(this.$attrs.challenge.ends).getTime()-today.getTime())/(one_day)),
        percDaysLeft: 0,
        currentFilter: 'all',
        totals: []
      };
    },
    mounted() {
      this.initialize() 
    },
    methods: {
      initialize() {
        this.percDaysLeft = this.daysLeft/365*100
        for (let activity in this.$attrs.activities) {
          if (this.$attrs.activities[activity].override_distance) {
            this.$attrs.activities[activity].distance = this.$attrs.activities[activity].override_distance
          }
        }

        this.athleteTotals = athleteTotals(this.$attrs.activities)
          
        for ( let athlete in this.athleteTotals ) {
          this.doTotals(this.athleteTotals[athlete])
        }

        this.activitesGrouped = groupBy(this.activitesGrouped, 'activity_type')
        for (let group in this.activitesGrouped) {
          this.athleteTotalsGrouped[group] = athleteTotals(this.activitesGrouped[group]);
          for ( let athlete in this.athleteTotalsGrouped[group] ) {
            this.doTotals(this.athleteTotalsGrouped[group][athlete])
          }
          this.athleteTotalsGrouped[group] = sortBy(this.athleteTotalsGrouped[group], 'progress')
        }

        this.athleteTotals = sortBy(this.athleteTotals, 'progress')
        this.totals = this.athleteTotals
      },
      athleteExists(athlete) {
        return this.athleteTotals.some(function(el) {
          return el.athlete === athlete;
        });
      },
      doTotals(athlete) {
        totalMi = athlete.distance/mi
        totalEl = athlete.elevation*ft
        totalTi = athlete.time/60
        let goal = this.athleteGoals.find(o => o.athlete === athlete.athlete).goal
        athlete.goal = goal
        athlete.progress = this.getProgress(goal)
        athlete.onTarget = this.percDaysLeft>(100-progress)
      },
      getProgress(goal) {
        switch(this.challenge.challenge_type) {
          case 'distance':
            return (totalMi/goal)*100;
          case 'elevation':
            return (totalEl/goal)*100;
          case 'time':
            return (totalTi/goal)*100;
        }
      },
      switchFilter(e, filter) {
        e.target.parentElement.parentElement.querySelector('.active').classList.remove('active')
        e.target.classList.add('active')
        this.currentFilter = filter
        if (filter === 'all') {
          this.totals = this.athleteTotals
        }else{
          this.totals = this.athleteTotalsGrouped[filter]
        }
      },
      getUnit() {
        return getUnit(this.challenge.challenge_type)
      },
      getTotal(item) {
        return getTotal(item, this.challenge.challenge_type)
      },
      numberWithCommas(n) {
        return numberWithCommas(n)
      },
    }
  }
</script>
<style lang="scss" scoped>
.athlete-name {
  text-transform: capitalize;
}

.bar {
  position: relative;
  background: white;
  border-radius: 25px;
  overflow: hidden;
  .bar-name, .bar-miles {
    position: absolute;
    left: 48%;
    top: 12px;
    text-transform: capitalize;
    color: #243546;
    font-weight: 700;
  }
  .bar-deets {
    position: absolute;
    right: 2px;
    top: 3px;
    text-transform: capitalize;
    color: #174776;
    display: inline-block;
    background: rgba(255, 255, 255, 0.8);
    padding: 6px;
    border-radius: 16px;
    font-size: 14px;
  }
  .bar-name {
    right: auto;
    left: 3px;
    top: 6px;
    font-weight: 500;
    background: #ffffff;
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid #d3e3ef
  }
  .bar-miles {
    left: auto;
    right: 3px;
    font-weight: 500;
  }
  .bar-width {
    background-color: #174776;
    height: 31px;
    border-radius: 25px;
  }
}

.bar-target {
  position: absolute;
  left: 3px;
  top: 3px;
  color: #174776;
  display: inline-block;
  background: rgba(255, 255, 255, 0.8);
  padding: 6px;
  border-radius: 16px;
  font-size: 14px;

  &.on-target {
    color: #02a517
  }
}

.bar-digits {
  color: #243546;
  font-weight: 700;
  height: 40px;
  padding-top: 12px;
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  color: white;
}

.mvp {
  color: #02a517;
}
</style>
